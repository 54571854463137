<template>
  <v-card>
    <v-toolbar elevation="2" flat>
      <v-card-subtitle>
        <strong>Salas que o usuário participa</strong>
      </v-card-subtitle>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="users"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    rooms: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Sala", value: "description" },
        { text: "Instituição", value: "institution_name" },
        { text: "Autorizado?", value: "authorized" },
      ],
      users: [],
    };
  },
  methods: {
    setUsers() {
      const cpData = [...this.rooms];

      this.users = cpData.map((u) => {
        return { ...u };
      });
    },
  },
  created() {
    this.setUsers();
  },
};
</script>

<style></style>
